.footer {
  padding-bottom: 6rem;
  padding-right: 1rem;
  padding-left: 1rem;
  color: #555;
}

.footer__dots {
  display: flex;
  justify-content: center;
  margin: 6rem 0;
}

.footer__dots span {
  display: block;
  width: 0.4rem;
  height: 0.4rem;
  margin: 0 0.2rem;
  background-color: var(--vg-lab-color);
  border-radius: 50%;
}

.footer__block {
  margin: 1.5rem 0;
}

.footer__block p {
  margin: 0;
  font-weight: var(--font-weight-semi-bold);
}

.footer__label {
  font-size: 0.8rem;
  color: #575757;
  font-weight: var(--font-weight-regular);
}

.footer__block a {
  color: #555;
  text-decoration: none;
  border-bottom: 0.2rem solid currentColor;
}

.footer__block a:hover {
  color: #000;
}

@media screen and (min-width: 750px) {
  .footer {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 0;
    padding-left: 0;
  }
}
