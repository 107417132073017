.section {
  margin-bottom: 4rem;
  padding: 0 1rem;
}

h2.section__heading {
  display: inline-block;
  margin: 0 0 1.5rem 0;
  padding: 0.5rem 0.6rem;
  font-size: 1rem;
  line-height: 1;
  font-weight: var(--font-weight-black);
  background-color: var(--vg-lab-color);
  color: #fff;
}

.section > * {
  line-height: 1.4;
  margin: 0 0 1.5rem 0;
  color: #444;
}

.section > *:last-child {
  margin-bottom: 0;
}

.section p b {
  font-size: 1.2rem;
  font-weight: var(--font-weight-semi-bold);
  color: #000;
}

p.section__lead {
  margin-top: 0;
  font-size: 1.2rem;
  font-weight: var(--font-weight-semi-bold);
  color: #000;
}

p.section__lead b {
  font-weight: var(--font-weight-black);
}

.section h3 {
  font-size: 1.25rem;
  font-weight: var(--font-weight-black);
}

.section h3 a {
  text-decoration: none;
  color: #333;
}

.section h4 {
  text-transform: uppercase;
  font-weight: var(--font-weight-semi-bold);
  color: #777;
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
}

@media screen and (min-width: 750px) {
  .section {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }
}
