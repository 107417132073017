.job {
}

.job__title {
  margin: 0 0 0.4rem 0;
  line-height: 1;
  font-size: 1.2rem;
  font-weight: var(--font-weight-black);
}

p.job__lead {
  margin: 0 0 0.8rem 0;
  font-size: 0.9rem;
  line-height: 1.2;
  font-style: italic;
  font-weight: var(--font-weight-semi-bold);
  color: #000;
}

p.job__external_link {
  margin: 0;
}

.job__external_link a {
  display: inline-block;
  padding-bottom: 0.1rem;
  font-weight: var(--font-weight-bold);
  color: #000;
  border-bottom: solid 0.2rem currentColor;
  text-decoration: none;
}

@media screen and (min-width: 750px) {
  .job {
    margin: 0;
    padding: 1.5rem;
    background-color: #fff;
    border-radius: 0.1rem;
    box-shadow: 0 0.1rem 1.25rem 0 rgba(0, 0, 0, 0.08),
      0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.04);
  }
}
