:root {
  --bg-color: #fcfcfc;
  --vg-lab-color: #000;
  --root-font-size: 18px;

  --font-weight-regular: 400;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-black: 900;
}

html {
  font-size: var(--root-font-size);
  background-color: var(--vg-lab-color);
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-size: 1rem;
  font-family: "Inter UI", sans-serif;
  background-color: var(--bg-color);
  color: #000;
  padding-top: 1rem;
}

@supports (font-variation-settings: normal) {
  body {
    font-family: "Inter UI var alt", sans-serif;
  }
}

@media screen and (min-width: 750px) {
  :root {
    --root-font-size: 20px;
  }
}
