.header {
  position: relative;
  display: flex;
  margin: 0 auto;
  height: 15rem;
  place-items: center;
  place-content: center;
  overflow: hidden;
}

.header__backdrop {
  pointer-events: none;
  user-select: none;
  position: absolute;
  text-align: justify;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-family: "Source Code Pro", monospace;
  font-weight: 500;
  color: #e9e9e9;
  padding: 0 1rem;
}

.header__backdrop::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    rgba(252, 252, 252, 0),
    rgba(252, 252, 252, 1)
  );
}

.header__backdrop svg {
  max-width: 100%;
}

.header__logo {
  position: relative;
  z-index: 1;
  max-width: 75%;
}

.header__logo a {
  display: block;
  margin-top: -2rem;
}

.header__logo img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  margin-top: -4rem;
}

.attention-please {
  position: relative;
  z-index: 1;
  padding: 1rem;
  border-left: 0.5rem solid var(--vg-lab-color);
  font-style: italic;
  font-size: 0.8rem;
  font-weight: var(--font-weight-semi-bold);
  margin-top: -3rem;
  margin-bottom: 3rem;
}

.attention-please p {
  margin: 0;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.attention-please p:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 750px) {
  .header {
    width: 1000px;
    height: 472px;
  }

  .header__logo a {
    margin-top: 0;
  }

  .header__logo img {
    max-width: 600px;
    height: auto;
  }

  .attention-please {
    font-size: 1rem;
    width: 800px;
    margin-right: auto;
    margin-left: auto;
  }
}
