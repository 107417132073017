.big-figure {
  margin-left: -1rem;
  margin-right: -1rem;
}

.big-figure__img {
  width: 100%;
  height: 100%;
  height: 300px;
  object-fit: cover;
  object-position: center top;
  vertical-align: bottom;
}

.big-figure__caption {
  margin-top: 0.5rem;
  color: #666;
  font-size: 0.7rem;
  padding: 0 0.5rem;
}

.repository-stats {
  display: none;
}

.projects-separator {
  display: flex;
  justify-content: center;
  margin: 2.5rem 0;
}

.projects-separator span {
  display: block;
  width: 0.3rem;
  height: 0.3rem;
  margin: 0 0.2rem;
  background-color: #ccc;
  border-radius: 50%;
}

a .external-link {
  width: 0.75em;
  height: 0.75em;
  opacity: 0.3;
  vertical-align: middle;
}

a:hover .external-link {
  opacity: 0.6;
}

@media screen and (min-width: 920px) {
  .big-figure {
    margin-left: -50px;
    margin-right: -50px;
  }

  .big-figure__img {
    height: 500px;
  }

  .big-figure__caption {
    padding: 0;
  }

  .repository-stats {
    display: block;
  }

  .repository-stats img {
    vertical-align: bottom;
    transform: translateX(calc(-50% + 400px));
  }
}
